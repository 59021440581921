import type { ColorOption, ProductAttribute } from '#types/product'

/**
 * Returns the color attribute object of the default color, or first color if a default is not defined
 *
 * @param {ProductAttribute[]} attributes - The array of attriebutes of an item
 * @example
 * getDefaultColorOption(product.attributes)
 * returns {
 *   defaultColor: true
 *   id: "NF0A3FJX11P"
 *   label: "Vintage White"
 *   ...
 *   }
 */
export const getDefaultColorOption = (attributes: ProductAttribute[]): ColorOption => {
  const colorAttribute = attributes.find(({ type }) => type.toLowerCase() === 'color')

  return colorAttribute?.options.find(({ defaultColor }) => defaultColor)
    || colorAttribute?.options[0]
}
