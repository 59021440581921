<template>
  <div ref="elementRef" v-style="background" @click="handlePromotionClick">
    <define-heading-template>
      <div>
        <div v-if="eyebrowText" v-style:c="eyebrowTextColor" class="uppercase">
          {{ eyebrowText }}
        </div>
        <base-picture
          v-if="eyebrowLogo"
          :src="eyebrowLogo.url"
          :alt="eyebrowLogo.alt"
          fit="contain"
          :position="{ sm: textAlignmentSmall || 'center', md: textAlignmentLarge || 'left' }"
          style="max-height: 9.5rem;"
        />
        <h1 v-if="title" v-style:c="titleColor" :class="titleStyle">
          <base-link v-if="mediaLink" :to="mediaLink.url" :target="mediaLink.targetAttribute">
            {{ title }}
          </base-link>
          <template v-else>
            {{ title }}
          </template>
        </h1>
      </div>
    </define-heading-template>
    <div class="relative grid">
      <div
        v-style:h="{ sm: '31rem', md: '42rem', lg: '50rem' }"
        class="relative flex grid-area-stack"
      >
        <base-picture
          v-if="responsiveMedia.images"
          v-bind="responsiveMedia.images"
          :lazy
          fit="cover"
          class="full"
        />
        <base-video
          v-if="responsiveMedia.video"
          v-bind="video"
          ref="videoRef"
          :autoplay="!lazy"
          :controls="false"
          loop
          class="full cover"
        />
        <base-link
          v-if="mediaLink"
          :to="mediaLink.url"
          :target="mediaLink.targetAttribute"
          aria-hidden="true"
          tabindex="-1"
          class="absolute-0"
        />
        <div v-style="gradient" class="absolute bottom-0 w-full" />
        <div

          class="absolute bottom-0 left-0 w-full flex items-end gap-4 p-4 md:p-12 md:pb-4"
        >
          <heading-template
            v-if="variant === 'productOverlayHero'"
            class="hidden grow md:inline"
          />
          <base-picture
            v-if="responsiveOverlayMedia?.images"
            v-style:mb="{ sm: '-4rem', md: '-13.5rem', lg: '-10.125rem' }"
            v-bind="responsiveOverlayMedia.images"
            :width="{ sm: 196, md: 271, lg: 344 }"
            :height="{ sm: 343, md: 467, lg: 538 }"
            class="shrink-0"
          />
        </div>
      </div>
      <div
        class="relative flex md:pointer-events-none md:p-12"
        :class="[
          contentAlignmentSmall[overlaySmall],
          contentAlignmentLarge[overlayLarge],
          { 'md:grid-area-stack': variant === 'standardHero' },
          { 'md:py-8': variant === 'blockBelowHero' },
          { '<md:mt-8 md:pt-4': variant === 'productOverlayHero' },
        ]"
      >
        <div
          v-style:bg="backdrop"
          class="space-y-2 md:p-6 md:-m-6 lg:space-y-4"
          :class="[
            { '<md:pt-8': hasCaption },
            overlayWidth && contentSize[overlayWidth],
            overlaySmall?.includes('overlay') ? 'p-2 -m-2' : '<md:bg-transparent',
          ]"
        >
          <heading-template v-style:mr="{ md: textAlignmentLarge === 'left' ? '24rem' : '0' }" :class="{ 'md:hidden': variant === 'productOverlayHero' }" />
          <p v-if="subtitle" v-style:c="subtitleColor" :class="subtitleStyle">
            {{ subtitle }}
          </p>
          <cms-rich-text
            v-if="richText"
            v-style:c="richTextColor || subtitleStyle"
            class="pointer-within"
            :content="{ richText }"
          />
          <div
            v-if="linkType !== 'No-CTA' && targets.length"
            class="pointer-within gap-4 i-flex <md:hidden lg:gap-6 not-first:pt-4"
          >
            <div
              v-style="equalTargetStyle"
              class="gap-4 wrap lg:gap-6"
              :class="[ctaAlignmentLarge[overlayLarge], isEqualWidthButtons ? 'grid' : 'flex']"
            >
              <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" :size />
            </div>
          </div>
          <cms-rich-text
            v-if="richTextBelowTargets"
            v-style:c="richTextBelowTargetsColor || subtitleStyle"
            class="pointer-within <md:hidden"
            :content="{ richText: richTextBelowTargets }"
          />
        </div>
      </div>
      <div
        v-if="hasCaption"
        v-style:c="captionColor || subtitleStyle"
        class="absolute right-0 mr-6"
        :class="captionPosition === 'bottom' ? 'bottom-0 mb-3' : 'top-0 mt-3'"
        style="max-width: 150px;"
      >
        {{ captionText }}
      </div>
    </div>
    <div
      v-if="linkType !== 'No-CTA' && targets.length"
      v-style="equalTargetStyle"
      class="f-col gap-4 px-4 lg:hidden md:hidden"
      :class="[equalTargetSize?.sm ? '' : ctaAlignmentSmall[overlaySmall], variant === 'standardHero' ? 'mt-6' : 'mt-2']"
    >
      <cms-shared-button v-for="(target, i) in targets" :key="i" v-bind="target" :size />
    </div>
    <cms-rich-text
      v-if="richTextBelowTargets"
      v-style:c="richTextBelowTargetsColor || subtitleStyle"
      class="pointer-within mt-2 px-4 md:hidden"
      :content="{ richText: richTextBelowTargets }"
    />
  </div>
</template>

<script lang="ts" setup>
import { SectionContextKey } from '#content/components/cms/section/context'
import type { HeroContent, OverlayLarge, OverlaySmall } from '#types/components/cms/hero'
import type { BaseVideo as BaseVideoPropType } from '#types/components/base/video'
import type { Responsive } from '#types/common'

const { content } = defineProps<{
  content: HeroContent
}>()

const {
  backdrop,
  captionColor,
  captionPosition,
  captionText,
  equalTargetSize,
  eyebrowLogo,
  eyebrowText,
  eyebrowTextColor,
  gradientBreakpoints = { sm: true },
  gradientStyle,
  gradientTone,
  linkType,
  media,
  mediaTarget,
  overlayLarge,
  overlaySmall,
  overlayWidth,
  overlayPicture,
  promotionTracking,
  richText,
  richTextColor,
  richTextBelowTargets,
  richTextBelowTargetsColor,
  subtitle,
  subtitleColor,
  subtitleStyle,
  targets = [],
  title,
  titleColor,
  titleStyle,
  variant = 'standardHero',
  mediaList
} = content

const [DefineHeadingTemplate, HeadingTemplate] = createReusableTemplate()

const { size } = useAppConfig().components.cms.sharedButton
const { getMedia, getVideo } = useCms()
const {
  elementRef,
  handlePromotionClick
} = usePromotionTracking(promotionTracking, inject(SectionContextKey), content.name)

const lazy = inject(LazyMedia)
const mediaLink = mediaTarget || targets[0]
const responsiveMedia = (mediaList || [media]).map(getMedia)[0]
const responsiveOverlayMedia = overlayPicture && getMedia(overlayPicture)

const video = ref<BaseVideoPropType>({ src: '' })
const videoRef = ref<{ $el: HTMLVideoElement }>()

const contentAlignmentLarge: Record<OverlayLarge, string> = {
  'left-left-top': 'md:items-start',
  'left-left-middle': 'md:items-center',
  'left-left-bottom': 'md:items-end',
  'left-right-middle': 'md:justify-end md:items-center',
  'center-left-middle': 'md:text-center md:items-center',
  'center-center-top': 'md:text-center md:justify-center md:items-start',
  'center-center-middle': 'md:text-center md:center',
  'center-center-bottom': 'md:text-center md:justify-center md:items-end',
  'center-right-middle': 'md:text-center md:justify-end md:items-center'
}

const contentAlignmentSmall: Record<OverlaySmall, string | any[]> = {
  'left-overlay': [{ '<md:grid-area-stack': variant === 'standardHero' }, '<md:pointer-events-none <md:items-center <md:p-4'],
  'left-below': '<md:mt-6 <md:items-start <md:px-4',
  'center-overlay': [{ '<md:grid-area-stack': variant === 'standardHero' }, '<md:pointer-events-none <md:center <md:p-4 <md:text-center'],
  'center-below': '<md:mt-6 <md:center <md:px-4 <md:text-center'
}

const contentSize = {
  xs: 'w-10/12 md:w-1/3',
  sm: 'w-10/12 md:w-1/2',
  md: 'w-11/12 md:w-2/3',
  lg: 'w-11/12'
}

const ctaAlignmentLarge: Partial<Record<OverlayLarge, string>> = {
  'center-left-middle': 'md:justify-center',
  'center-center-top': 'md:justify-center',
  'center-center-middle': 'md:justify-center',
  'center-center-bottom': 'md:justify-center',
  'center-right-middle': 'md:justify-center'
}

const ctaAlignmentSmall: Record<OverlaySmall, string> = {
  'left-overlay': '<md:items-start',
  'left-below': '<md:items-start',
  'center-overlay': '<md:items-center',
  'center-below': '<md:items-center'
}

const textAlignmentLarge = overlayLarge.split('-')[0]
const textAlignmentSmall = overlaySmall.split('-')[0]

const gradientTones = {
  light: 'white',
  dark: 'black',
  off: 'transparent'
}

const gradientStyles = {
  full: 35,
  focused: 50,
  off: 0
}

const hasCaption = captionText && captionPosition !== 'off'

const gradient = {
  display: Object.entries(gradientBreakpoints).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value ? 'block' : 'none'
  }), {} as Responsive<string>),
  bgv: `linear-gradient(to bottom, transparent, ${gradientTones[gradientTone || 'off']})`,
  h: `${gradientStyles[gradientStyle || 'off']}%`,
}

const background = {
  bg: Object.entries(gradientBreakpoints).reduce((acc, [key, value]) => ({
    ...acc,
    [key]: value ? gradientTones[gradientTone || 'off'] : 'transparent'
  }), {} as Responsive<string>)
}

const isEqualWidthButtons = getValueForBreakpoint('sm', equalTargetSize)

const equalTargetStyle = {
  ...(isEqualWidthButtons && {
    'grid-cols': {
      sm: '1fr',
      md: `repeat(${targets.length}, minmax(0,1fr))`
    }
  })
}

onMounted(() => {
  if ('video' in responsiveMedia) {
    video.value = getVideo(responsiveMedia.video) as BaseVideoPropType
    if (videoRef.value) {
      videoRef.value.$el.load()
      videoRef.value.$el.play()
    }
  }
})
</script>
